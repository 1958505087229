import BoxSizeReducerDTO, { BoxSizeAction } from '@root/interfaces/BoxSizeReducerDTO';
import types from '@root/store/actions';

const initialState: BoxSizeReducerDTO = {
  boxSizes: [],
  countBoxsizes: 0,
  loading: false,
  isInitialized: false,
};

// ===========================|| BOX SIZE REDUCER ||=========================== //

type BoxSizeReducerType = (
  state: BoxSizeReducerDTO,
  action: BoxSizeAction,
) => BoxSizeReducerDTO;

const boxsizesReducer: BoxSizeReducerType = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.boxsizesCreate: {
      const { row } = payload;
      return {
        ...state,
        boxSizes: [...state.boxSizes, row],
      };
    }
    case types.boxsizesList: {
      const { rows } = payload;
      return {
        ...state,
        boxSizes: rows,
        isInitialized: true,
        loading: false,
      };
    }
    case types.boxsizesById:
      return { ...state };

    case types.boxsizesUpdate: {
      const { row } = payload;
      return {
        ...state,
        boxSizes: state.boxSizes.map((e) => (e.id === row.id ? { ...e, ...row } : e)),
      };
    }

    case types.boxsizesPartialUpdate: {
      const { row } = payload;
      return {
        ...state,
        boxSizes: state.boxSizes.map((e) => (e.id === row.id ? { ...e, ...row } : e)),
      };
    }

    case types.boxsizesDestroy: {
      const { id } = payload;
      const deletedSet = new Set([id]);
      return {
        ...state,
        boxSizes: state.boxSizes.filter(({ id }) => !deletedSet.has(id)),
      };
    }
    default:
      return state;
  }
};

export default boxsizesReducer;
