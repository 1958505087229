const types = {
  // UI
  THEME_LOCALE: '[UI] THEME_LOCALE',

  // AUTH
  AUTH_LOGIN: '[AUTH] login',
  AUTH_RENEW_TOKEN: '[AUTH] renew token',
  AUTH_LOGOUT: '[AUTH] logout',
  AUTH_RESET_PASSWORD: '[AUTH] reset password',
  AUTH_VALIDATE_RESET_PASSWORD_TOKEN: '[AUTH] validate reset password token',

  // SUPPLIERS
  SUPPLIERS_LIST: '[SUPPLIER] list',
  SUPPLIERS_CREATE: '[SUPPLIER] create',
  SUPPLIERS_UPDATE: '[SUPPLIER] update',
  SUPPLIERS_DESTROY: '[SUPPLIER] destroy',

  // SUPPLIERS CATALOG
  SUPPLIERS_CATALOG_LIST: '[SUPPLIER CATALOG] list',
  SUPPLIERS_CATALOG_CREATE: '[SUPPLIER CATALOG] create',
  SUPPLIERS_CATALOG_UPDATE: '[SUPPLIER CATALOG] update',
  SUPPLIERS_CATALOG_DESTROY_ITEM: '[SUPPLIER CATALOG] destroy item',
  SUPPLIERS_CATALOG_DESTROY_ALL: '[SUPPLIER CATALOG] destroy all',

  // INVENTORY
  INVENTORY_LIST: '[INVENTORY] list',
  INVENTORY_MOVEMENTS_LIST: '[INVENTORY] list',

  // PRODUCTS
  productsLoading: '[PRODUCTS] loading products data',
  productsList: '[PRODUCTS] list products',
  productsFinishLoading: '[PRODUCTS] finish loading products data',
  productsInitNew: '[PRODUCTS] adding product',
  productsFinishtNew: '[PRODUCTS] finish adding product',
  productsSetActiveProduct: '[PRODUCTS] get product',
  productsCleanActiveProduct: '[PRODUCTS] clean product',
  productsUpdateProduct: '[PRODUCTS] update product',
  productsDelete: '[PRODUCTS] delete product',
  productsImportCSV: '[PRODUCTS] import csv',
  productsFinishImportCSV: '[PRODUCTS] finish import csv',

  // PRODUCTS CATALOG
  PRODUCTS_CATALOG_LIST: '[PRODUCTS CATALOG] list',
  PRODUCTS_CATALOG_CREATE: '[PRODUCTS CATALOG] create',
  PRODUCTS_CATALOG_UPDATE: '[PRODUCTS CATALOG] update',
  PRODUCTS_CATALOG_DESTROY: '[PRODUCTS CATALOG] destroy',

  // BOX SIZES
  boxsizesList: '[BOX_SIZES] list',
  boxsizesById: '[BOX_SIZES] find by id',
  boxsizesCreate: '[BOX_SIZES] create',
  boxsizesUpdate: '[BOX_SIZES] update',
  boxsizesPartialUpdate: '[BOX_SIZES] partial update',
  boxsizesDestroy: '[BOX_SIZES] destroy',

  // ASINS
  ASINS_LIST: '[ASINS] list asins',
  ASINS_UPDATE_LIST: '[ASINS] update list',
  ASINS_REQUEST_ERROR: '[ASINS] REQUEST_ERROR',
  ASINS_FETCH_INIT: '[ASINS] FETCH_INIT',
  ASINS_UPDATE_QUERY: '[ASINS] UPDATE_QUERY',
  ASINS_CHANGE_PAGE_SIZE: '[ASINS] CHANGE PAGE SIZE',
  ASINS_FORCE_RELOAD: '[ASINS] FORCE RELOAD',
  ASINS_CREATE: '[ASINS] CREATE',
  ASINS_UPDATE_ROW: '[ASINS] update row',
  ASINS_PARTIAL_UPDATE: '[ASINS] partial update',
  ASINS_DESTROY: '[ASINS] destroy',
  ASINS_DESTROY_LIST: '[ASINS] destroy list',
  ASINS_FILTER: '[ASINS] filter',

  // PRE REQUIREMENTS
  PREP_REQ_LIST: '[PREP_REQUIREMENTS] list',
  PREP_REQ_BY_ID: '[PREP_REQUIREMENTS] find by id',
  PREP_REQ_CREATE: '[PREP_REQUIREMENTS] create',
  PREP_REQ_UPDATE: '[PREP_REQUIREMENTS] update',
  PREP_REQ_PARTIAL_UPDATE: '[PREP_REQUIREMENTS] partial update',
  PREP_REQ_DESTROY: '[PREP_REQUIREMENTS] destroy',

  // PURCHASE ORDERS OPEN
  PO_OPEN_LIST: '[PURCHASE_ORDERS_OPEN] list',
  PO_OPEN_UPDATE: '[PURCHASE_ORDERS_OPEN] update',
  PO_OPEN_DESTROY: '[PURCHASE_ORDERS_OPEN] destroy',

  // PURCHASE ORDERS BY SUPPLIER
  PO_BY_SUPPL_LIST: '[PURCHASE_ORDERS_BY_SUPPPLIER] list',
  PO_BY_SUPPL_UPDATE: '[PURCHASE_ORDERS_BY_SUPPPLIER] update',
  PO_BY_SUPPL_MOVE_TO_ADDED: '[PURCHASE_ORDERS_BY_SUPPPLIER] move to added',
  PO_BY_SUPPP_DESTROY: '[PURCHASE_ORDERS_BY_SUPPPLIER] destroy',

  // PURCHASE ORDERS TO PASS
  purchaseOrdersList: '[PURCHASE_ORDERS] list',
  purchaseOrdersCreate: '[PURCHASE_ORDERS] create',
  purchaseOrdersProductUpdate: '[PURCHASE_ORDERS] update product',
  purchaseOrdersDestroy: '[PURCHASE_ORDERS] destroy',

  // PURCHASE ORDERS TO PASS ASIN
  purchaseOrdersAsinUpdate: '[PURCHASE_ORDERS_ASIN] update',
  purchaseOrdersAsinDestroy: '[PURCHASE_ORDERS_ASIN] destroy',

  // USERS
  USERS_SET_LIST: '[USERS] list',
  USERS_ADD: '[USERS] create',
  USERS_UPDATE: '[USERS] update',
  USERS_DESTROY: '[USERS] destroy',
};

export default types;
