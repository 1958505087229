import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { SETTINGS_GENERAL } from '@root/constants/navigation';

const SettingsDropdown = () => {
  const navigate = useNavigate();

  return (
    <div className="mr-3">
      <Button
        id="dropdown-basic"
        variant="white"
        className="btn-rounded-circle pt-0 pb-0 d-flex justify-content-center align-items-center"
        style={{ width: '2rem', height: '2rem' }}
        onClick={() => navigate(SETTINGS_GENERAL)}>
        <span className="fe fe-settings" style={{ fontSize: 15 }}></span>
      </Button>
    </div>
  );
};

export default SettingsDropdown;
