import requests from './requests';
import fetchApi from './fetchApi';

const getBySupplier = (supplier_id) => {
  const url = `/suppliers:availability/${supplier_id}/`;

  return fetchApi(requests.get(url));
};

const create = (body) => {
  const url = `/suppliers:availability/`;

  return fetchApi(requests.post(url, body));
};

const update = (id, body) => {
  const url = `/suppliers:availability/${id}/`;

  return fetchApi(requests.patch(url, body));
};

const destroy = (id) => {
  const url = `/suppliers:availability/${id}/`;

  return fetchApi(requests.delete(url));
};

const availability = {
  getBySupplier,
  create,
  update,
  destroy,
};

export default availability;
