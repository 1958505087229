import { lazy } from 'react';

import * as ROUTES from '@root/constants/navigation';
import MinimalLayout from '@root/layout/MinimalLayout';
import Loadable from '@root/ui-component/Loadable';

const DesignTables = Loadable(lazy(() => import('@root/pages/design/Tables')));

const UnauthorizedPage = Loadable(lazy(() => import('@root/pages/maintenance/Unauthorized')));

const PublicError = Loadable(lazy(() => import('@root/pages/maintenance/Error')));

// ==============================|| AUTH ROUTING ||============================== //

const PublicRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: ROUTES.DESIGN_TABLE,
      element: <DesignTables />,
    },
    {
      path: ROUTES.UNAUTHORIZED,
      element: <UnauthorizedPage />,
    },
    {
      path: ROUTES.NOT_FOUND,
      element: <PublicError />,
    },
    {
      path: '*',
      element: <PublicError />,
    },
  ],
};

export default PublicRoutes;
