export const ADMIN = 'ADM';
export const DATA_ENTRY_LV1 = 'DATA ENTRY LV1';
export const DATA_ENTRY_LV2 = 'DATA ENTRY LV2';
export const BUYER_LV1 = 'BUYER LV1';
export const BUYER_LV2 = 'BUYER LV2';
export const WH_STAFF = 'WH STAFF';

export const ALL_ROLES = [
  ADMIN,
  DATA_ENTRY_LV1,
  DATA_ENTRY_LV2,
  BUYER_LV1,
  BUYER_LV2,
  WH_STAFF,
];

export const ROLES_STR = [
  {
    enum: 'ADM',
    value: 'Admin',
  },
  {
    enum: 'DATA ENTRY LV1',
    value: 'Data Entry lv1',
  },
  {
    enum: 'DATA ENTRY LV2',
    value: 'Data Entry lv2',
  },
  {
    enum: 'BUYER LV1',
    value: 'Buyer lv1',
  },
  {
    enum: 'BUYER LV2',
    value: 'Buyer lv2',
  },
  {
    enum: 'WH STAFF',
    value: 'WH Staff',
  },
];
