import { lazy } from 'react';

import GuestGuard from '@root/utils/route-guard/GuestGuard';
import MinimalLayout from '@root/layout/MinimalLayout';
import Loadable from '@root/ui-component/Loadable';
import { FORGOT_PASSWORD, LOGIN, RESET_PASSWORD } from '@root/constants/navigation';

const AuthLogin = Loadable(lazy(() => import('@root/pages/authentication/Login')));
const AuthResetPassword = Loadable(
  lazy(() => import('@root/pages/authentication/ResetPassword')),
);
const AuthForgotPassword = Loadable(
  lazy(() => import('@root/pages/authentication/ForgotPassword')),
);
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: (
    <GuestGuard>
      <MinimalLayout />
    </GuestGuard>
  ),
  children: [
    {
      path: LOGIN,
      element: <AuthLogin />,
    },
    {
      path: FORGOT_PASSWORD,
      element: <AuthForgotPassword />,
    },
    {
      path: RESET_PASSWORD,
      element: <AuthResetPassword />,
    },
  ],
};

export default LoginRoutes;
