import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import jwt from 'jwt-decode';

import { authService, usersService } from '@root/services';
import setSession from '@root/utils/setSession';
import types from '@root/store/actions';

const startLogin = (username, password) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    authService
      .tokenObtain({ username, password })
      .then((data) => {
        // console.debug('[LOGIN]', data);
        const user = data.user;
        setSession(data.access, data.refresh);
        dispatch(actionLogin(user, data.access, data.refresh));
        toast.success(`Welcome ${user.username}!!!`);
        resolve(data);
      })
      .catch((e) => reject(e));
  });
};

const checkAuthenticated = () => async (dispatch) => {
  const token = localStorage.getItem('token');
  const tokenRefresh = localStorage.getItem('token-refresh');
  return new Promise((resolve, reject) => {
    if (!token || !tokenRefresh) {
      setSession();
      dispatch(actionRenewToken());
      return reject();
    }
    authService
      .tokenRefresh({ refresh: tokenRefresh })
      .then((data) => {
        const decodeToken = jwt(data.access);
        usersService
          .getById(decodeToken?.user_id)
          .then((user) => {
            setSession(data.access, tokenRefresh);
            dispatch(actionLogin(user, data.access, tokenRefresh));
            resolve(data);
          })
          .catch((e) => {
            setSession();
            dispatch(actionRenewToken());
            reject(e);
          });
      })
      .catch((e) => {
        setSession();
        dispatch(actionRenewToken());
        // Swal.close();
        reject(e);
      });
  });
};

const resetPassword = (body) => async (dispatch) => {
  await authService
    .passwordResetRequest(body)
    .then((data) => {
      dispatch(actionResetPassword());
      toast.success(data.message);
    })
    .catch((e) => {
      Swal.fire('Auth Error', e?.detail ? e?.detail : e?.message, 'error');
    });
};

const validateResetPasswordToken = (token, password) => async (dispatch) => {
  await authService
    .passwordResetApply({ token, password })
    .then((data) => {
      dispatch(actionValidateResetPasswordToken());
      toast.success(data.message + ', Will redirect to login page in few seconds');
    })
    .catch((e) => {
      Swal.fire('Auth Error', e?.detail ? e?.detail : e?.message, 'error');
    });
};

const startLogout = () => (dispatch) => {
  setSession();
  dispatch(actionLogout());
};

const actionLogin = (user, token, refreshToken) => ({
  type: types.AUTH_LOGIN,
  payload: { user, token, refreshToken },
});

const actionRenewToken = () => ({
  type: types.AUTH_RENEW_TOKEN,
});

const actionLogout = () => ({
  type: types.AUTH_LOGOUT,
});

const actionResetPassword = () => ({
  type: types.AUTH_RESET_PASSWORD,
});

const actionValidateResetPasswordToken = () => ({
  type: types.AUTH_VALIDATE_RESET_PASSWORD_TOKEN,
});

export {
  startLogin,
  checkAuthenticated,
  actionLogout,
  startLogout,
  resetPassword,
  validateResetPasswordToken,
};
