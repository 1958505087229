import styles from './loader.module.css';

const Loader = () => {
  return (
    <div className={styles.root}>
      <span className={styles.progress}>
        <span className={styles.bar1}></span>
        <span className={styles.bar2}></span>
      </span>
    </div>
  );
};

export default Loader;
