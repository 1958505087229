import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { DASHBOARD } from '@root/constants/navigation';

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */
const GuestGuard = ({ children }) => {
  const { logged } = useSelector((state) => state.auth);

  return logged ? <Navigate to={DASHBOARD} replace /> : children;
};

export default GuestGuard;
