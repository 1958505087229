import requests from './requests';
import fetchApi from './fetchApi';

const getAll = () => {
  const url = '/suppliers/';

  return fetchApi(requests.get(url));
};

const getById = (supplier_id) => {
  const url = `/suppliers/${supplier_id}/`;

  return fetchApi(requests.get(url));
};

const create = (body) => {
  const url = '/suppliers/';

  return fetchApi(requests.post(url, body));
};

const update = (supplier_id, body) => {
  const url = `/suppliers/${supplier_id}/`;

  return fetchApi(requests.patch(url, body));
};

const destroy = (supplier_id) => {
  const url = `/suppliers/${supplier_id}/`;

  return fetchApi(requests.delete(url));
};

const importBuylistCSV = (supplier_id, body) => {
  const url = `/suppliers/${supplier_id}/import-buylist-csv/`;

  return fetchApi(requests.post(url, body));
};

const getAllCatalogBySupplier = (supplier_id) => {
  const url = `/suppliers/${supplier_id}/catalog/`;

  return fetchApi(requests.get(url));
};

const getCatalogBySupplierById = (supplier_id, catalog_id) => {
  const url = `/suppliers/${supplier_id}/catalog/${catalog_id}/`;

  return fetchApi(requests.get(url));
};

const createCatalogBySupplier = (supplier_id, body) => {
  const url = `/suppliers/${supplier_id}/catalog/`;

  return fetchApi(requests.post(url, body));
};

const updateCatalogBySupplier = (supplier_id, catalog_id, body) => {
  const url = `/suppliers/${supplier_id}/catalog/${catalog_id}/`;

  return fetchApi(requests.patch(url, body));
};

const destroyCatalogBySupplierById = (supplier_id, catalog_id) => {
  const url = `/suppliers/${supplier_id}/catalog/${catalog_id}/`;

  return fetchApi(requests.delete(url));
};

const destroyAllCatalogBySupplier = (supplier_id) => {
  const url = `/suppliers/${supplier_id}/catalog/`;

  return fetchApi(requests.delete(url));
};

const uploadCSVCatalogBySupplier = (supplier_id, body) => {
  const url = `/suppliers/${supplier_id}/catalog:upload/`;

  return fetchApi(requests.post(url, body));
};

const filterCatalogSupplierSKUs = (supplier_id, body) => {
  const url = `/suppliers/${supplier_id}/catalog:filter/`;

  return fetchApi(requests.post(url, body));
};

const suppliers = {
  getAll,
  getById,
  create,
  update,
  destroy,
  importBuylistCSV,
  getAllCatalogBySupplier,
  getCatalogBySupplierById,
  createCatalogBySupplier,
  updateCatalogBySupplier,
  destroyCatalogBySupplierById,
  destroyAllCatalogBySupplier,
  uploadCSVCatalogBySupplier,
  filterCatalogSupplierSKUs,
};

export default suppliers;
