import styles from './statistic.module.scss';

const Statistic = (props) => {
  const { title, prefix, value, suffix, small = false, titleBold = false, ...others } = props;
  return (
    <div className={styles.root} {...others}>
      <div
        className={`${styles.title} text-alt ${titleBold && 'text-alt'}`}
        style={{ fontSize: small && '13px' }}>
        {title}
      </div>
      <div className={`${styles.content} text-body`}>
        {prefix && <span className={styles.prefix}>{prefix}</span>}
        <span className={styles.value} style={{ fontSize: small && '18px' }}>
          {value}
        </span>
        {suffix && <span className={styles.suffix}>{suffix}</span>}
      </div>
    </div>
  );
};

export default Statistic;
