import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import {
  DASHBOARD,
  ASINS,
  PRODUCTS,
  SUPPLIERS,
  USERS,
  PURCHASE_ORDERS_OPEN,
  AMAZON_INVENTORY,
} from '@root/constants/navigation';
import {
  ALL_ROLES,
  ADMIN,
  DATA_ENTRY_LV2,
  BUYER_LV1,
  BUYER_LV2,
  WH_STAFF,
} from '@root/constants/roles';
import Navbar from './Navbar';
import SideNavbar from './SideNavbar';
import Drawer from './Drawer';
import styles from './MainLayout.module.scss';

interface MainLayoutProps {
  hasSidenav?: boolean;
}

const routes = [
  {
    id: 'home',
    title: 'Home',
    url: DASHBOARD,
    allowedRoles: [...ALL_ROLES],
  },
  {
    id: 'asin',
    title: "ASIN's",
    url: ASINS,
    allowedRoles: [...ALL_ROLES],
  },
  {
    id: 'products',
    title: 'Inventory',
    url: PRODUCTS,
    allowedRoles: [ADMIN, DATA_ENTRY_LV2, BUYER_LV1, BUYER_LV2],
  },
  {
    id: 'suppliers',
    title: 'Suppliers',
    url: SUPPLIERS,
    allowedRoles: [ADMIN],
  },
  {
    id: 'purchase_orders',
    title: 'Purchase Orders',
    url: PURCHASE_ORDERS_OPEN,
    allowedRoles: [ADMIN, BUYER_LV1, BUYER_LV2, WH_STAFF],
  },
  {
    id: 'users',
    title: 'Users',
    url: USERS,
    allowedRoles: [ADMIN],
  },
  {
    id: 'amazon',
    title: 'Amazon',
    url: AMAZON_INVENTORY,
    allowedRoles: [...ALL_ROLES],
    children: [
      {
        id: 'inventory',
        title: 'Inventory',
        url: AMAZON_INVENTORY,
        allowedRoles: [...ALL_ROLES],
      },
    ],
  },
];

const MainLayout = (props: MainLayoutProps) => {
  const { hasSidenav } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className={styles.content}>
      <div className={styles.wrap}>
        <Navbar routes={routes} handleShow={handleShow} />
        <Drawer routes={routes} show={show} handleClose={handleClose} />
        <div className="d-flex flex-nowrap position-relative overflow-hidden h-100">
          <SideNavbar hasSidenav={hasSidenav} />
          <main role="main" className={styles.main}>
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
