import fetchApi from './fetchApi';
import requests from './requests';

const getAll = () => {
  const url = '/box-sizes/';

  return fetchApi(requests.get(url));
};

const getById = (boxsizes_id) => {
  const url = `/box-sizes/${boxsizes_id}/`;

  return fetchApi(requests.get(url));
};

const create = (body) => {
  const url = '/box-sizes/';

  return fetchApi(requests.post(url, body));
};

const update = (boxsizes_id, body) => {
  const url = `/box-sizes/${boxsizes_id}/`;

  return fetchApi(requests.patch(url, body));
};

const destroy = (boxsizes_id) => {
  const url = `/box-sizes/${boxsizes_id}/`;

  return fetchApi(requests.delete(url));
};

const boxSizes = {
  getAll,
  getById,
  create,
  update,
  destroy,
};

export default boxSizes;
