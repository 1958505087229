import fetchApi from './fetchApi';
import requests from './requests';

const getAllByFilter = (type = 'Supplier', status = 'Working') => {
  const url = `/purchase-orders/?type=${type}&status=${status}`;

  return fetchApi(requests.get(url));
};

const getListOpen = () => {
  const url = '/purchase-orders/';

  return fetchApi(requests.get(url));
};

const getById = (id) => {
  const url = `/purchase-orders/${id}/`;

  return fetchApi(requests.get(url));
};

const create = (body) => {
  const url = '/purchase-orders/';

  return fetchApi(requests.post(url, body));
};

const update = (id, body) => {
  const url = `/purchase-orders/${id}/`;

  return fetchApi(requests.patch(url, body));
};

const destroy = (id) => {
  const url = `/purchase-orders/${id}/`;

  return fetchApi(requests.delete(url));
};

const purchaseOrders = {
  getAllByFilter,
  getListOpen,
  getById,
  create,
  update,
  destroy,
};

export default purchaseOrders;
