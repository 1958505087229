import fetchApi from './fetchApi';
import requests from './requests';

const getAll = () => {
  const url = '/users/';

  return fetchApi(requests.get(url));
};

const getById = (user_id) => {
  const url = `/users/${user_id}/`;

  return fetchApi(requests.get(url));
};

const create = (body) => {
  const url = '/users/';

  return fetchApi(requests.post(url, body));
};

const update = (user_id, body) => {
  const url = `/users/${user_id}/`;

  return fetchApi(requests.patch(url, body));
};

const destroy = (user_id) => {
  const url = `/users/${user_id}/`;

  return fetchApi(requests.delete(url));
};

const users = {
  getAll,
  getById,
  create,
  update,
  destroy,
};

export default users;
