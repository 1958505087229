import axios from '@root/utils/axios';
import type { AxiosResponse } from 'axios';

import type { BodyType, ResponseType } from '@root/types/RequestTypes';

function responseBody(response: AxiosResponse): ResponseType {
  const { request, status, data } = response;
  /*
  console.debug(
    '[Response]',
    response.config.method.toUpperCase(),
    response.config.baseURL + response.config.url,
    response,
  );
  */

  return {
    request,
    status,
    data,
  };
}

const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body: BodyType) => axios.post(url, body).then(responseBody),
  put: (url: string, body: BodyType) => axios.put(url, body).then(responseBody),
  patch: (url: string, body: BodyType) => axios.patch(url, body).then(responseBody),
  delete: (url: string) => axios.delete(url).then(responseBody),
};

export default requests;
