import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { useUI } from '@root/hooks';
import { useAppDispatch, useAppSelector } from '@root/hooks/useRedux';
import { checkAuthenticated } from '@root/actions/authActions';
import Routes from '@root/router';
import Loader from '@root/ui-component/Loader';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'react-toastify/dist/ReactToastify.css';
import '@root/styles/index.scss';

const App = () => {
  const dispatch = useAppDispatch();
  const { isInitialized } = useAppSelector((state) => state.auth);
  const { ui, setUITheme } = useUI();

  const queryClient = new QueryClient();

  useEffect(() => {
    const themeMode = window.localStorage.getItem('THEME_LOCALE');
    setUITheme(themeMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(checkAuthenticated());
  }, [dispatch]);

  useEffect(() => {
    switch (ui.theme) {
      case 'light':
        document.body.classList.remove('dark-content');
        break;
      case 'dark':
      default:
        document.body.classList.add('dark-content');
        break;
    }
  }, [ui.theme]);

  if (!isInitialized) {
    return <Loader />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Routes />
      <ToastContainer
        position="top-right"
        theme="colored"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        closeOnClick
        pauseOnHover
        draggable
      />
    </QueryClientProvider>
  );
};

export default App;
