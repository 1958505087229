import types from '@root/store/actions';

const initialState = {
  posOpen: [],
  countPOsOpen: 0,
};

const purchaseOrdersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.PO_OPEN_LIST: {
      const { row } = payload;
      return {
        ...state,
        posOpen: row,
        countPOsOpen: row?.length,
      };
    }
    case types.PO_OPEN_UPDATE: {
      const { id, status } = payload;
      return {
        ...state,
        posOpen: state.posOpen.map((po) => (po.id === id ? { ...po, status: status } : po)),
      };
    }
    case types.PO_OPEN_DESTROY: {
      const { id } = payload;
      return {
        ...state,
        posOpen: state.posOpen.filter((po) => po.id !== id),
      };
    }
    case type.purchaseOrdersCreate:
      return {
        ...state,
        status: payload.status,
        purchaseOrders: [state.purchaseOrders, payload.purchaseOrders],
      };

    case types.purchaseOrdersList:
      return {
        ...state,
        status: payload.status,
        purchaseOrders: payload.purchaseOrders,
        purchaseOrdersIds: payload.purchaseOrdersIds,
      };

    case types.purchaseOrdersProductUpdate:
      return {
        ...state,
        purchaseOrders: state.purchaseOrders.map((purchOrder) =>
          purchOrder.id === payload.id
            ? { ...purchOrder, asin: { ...purchOrder.asin, product: payload.product } }
            : purchOrder,
        ),
      };

    case types.purchaseOrdersAsinUpdate:
      return {
        ...state,
        purchaseOrders: state.purchaseOrders.map((purchOrder) =>
          purchOrder.id === payload.id
            ? { ...purchOrder, ...payload.purchaseOrderAsin }
            : purchOrder,
        ),
      };

    case types.purchaseOrdersDestroy:
      const deletedSet = new Set(payload);
      return {
        ...state,
        purchaseOrders: state.purchaseOrders.filter(({ root_id }) => !deletedSet.has(root_id)),
      };

    case types.purchaseOrdersAsinDestroy:
      return {
        ...state,
        purchaseOrders: state.purchaseOrders.filter(
          ({ purchase_order_asin_id }) => purchase_order_asin_id !== action.payload,
        ),
      };

    default:
      return state;
  }
};

export default purchaseOrdersReducer;
