export const getErrorMessage = (res) => {
  if (res?.detail) {
    return res?.detail;
  }

  if (res?.message) {
    return res?.message;
  }

  const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
  if (isHTML(res)) {
    return (
      <div className="overflow-hidden text-left">
        <iframe
          title="Error Message"
          srcDoc={res}
          style={{ height: 384, width: '100%', overflowX: 'hidden' }}
        />
      </div>
    );
  }

  return (
    <div className="card bg-light border text-left m-0 p-3">
      <pre className="text-secondary mb-0">{JSON.stringify(res, null, 2)}</pre>
    </div>
  );
};

export const getFooterError = (config) => {
  return (
    <div>
      <span className="text-uppercase text-info">
        <strong>[{config?.method}] </strong>
      </span>
      <span>{config?.baseURL}</span>
      <span>{config?.url}</span>
    </div>
  );
};
