import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NavDropdown } from 'react-bootstrap';

import cls from '@root/utils/cls';

const NavItem = ({ url, title, allowedRoles = [], children }) => {
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const hasRequiredRole = user?.role ? allowedRoles.includes(user?.role) : false;
  const isActive = url ? !!matchPath({ path: url, end: false }, location.pathname) : false;

  if (!hasRequiredRole) {
    return null;
  }

  if (children) {
    return (
      <NavDropdown title={title} id="topnav" active={isActive}>
        {children.map((item) => {
          const hasRequiredRole = user?.role ? item.allowedRoles.includes(user?.role) : false;
          if (!hasRequiredRole) {
            return null;
          }
          return (
            <NavDropdown.Item key={item.id} as={RouterLink} to={item.url}>
              {item.title}
            </NavDropdown.Item>
          );
        })}
      </NavDropdown>
    );
  }

  return (
    <li className="nav-item">
      <RouterLink className={cls('nav-link', { 'is-valid': isActive })} to={url} role="button">
        {title}
      </RouterLink>
    </li>
  );
};

export default NavItem;
