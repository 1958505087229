import { useDispatch, useSelector } from 'react-redux';

import actionTypes from '@root/store/actions';

const useUI = () => {
  const ui = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  const actionSetUITheme = (content) => {
    return dispatch({
      type: actionTypes.THEME_LOCALE,
      ...content,
    });
  };

  const setUITheme = (theme) => {
    actionSetUITheme({ theme });
  };

  const arg = {
    ui,
    setUITheme,
  };

  return arg;
};

export default useUI;
