import { lazy } from 'react';

import * as ROLES from '@root/constants/roles';
import * as ROUTES from '@root/constants/navigation';
import AuthGuard from '@root/utils/route-guard/AuthGuard';
import MainLayout from '@root/layout/MainLayout';
import Loadable from '@root/ui-component/Loadable';

const DashboardDefault = Loadable(lazy(() => import('@root/pages/Dashboard')));

const AsinsList = Loadable(lazy(() => import('@root/pages/asins/AsinsList')));
const AsinPreview = Loadable(lazy(() => import('@root/pages/asins/AsinPreview')));

const ProductList = Loadable(lazy(() => import('@root/pages/products/ProductList')));
const ProductsNew = Loadable(lazy(() => import('@root/pages/products/ProductsNew')));
const ProductsUpdate = Loadable(lazy(() => import('@root/pages/products/ProductsUpdate')));
const ProductsImportCSV = Loadable(
  lazy(() => import('@root/pages/products/ProductsImportCSV')),
);

const ProductsCatalogList = Loadable(
  lazy(() => import('@root/pages/productsCatalog/ProductsCatalogList')),
);
const ProductsCatalogCreate = Loadable(
  lazy(() => import('@root/pages/productsCatalog/ProductCatalogCreate')),
);
const ProductsCatalogUpdate = Loadable(
  lazy(() => import('@root/pages/productsCatalog/ProductCatalogUpdate')),
);
const ProductsCatalogImportCSV = Loadable(
  lazy(() => import('@root/pages/productsCatalog/ProductCatalogImportCSV')),
);

const InventoryList = Loadable(lazy(() => import('@root/pages/inventory/InventoryList')));
const InventoryMovements = Loadable(
  lazy(() => import('@root/pages/inventory/InventoryMovements')),
);
const AmazonList = Loadable(lazy(() => import('@root/pages/inventory/AmazonList')));

const SuppliersList = Loadable(lazy(() => import('@root/pages/suppliers/SuppliersList')));
const SuppliersNew = Loadable(lazy(() => import('@root/pages/suppliers/SuppliersNew')));
const SuppliersUpdate = Loadable(lazy(() => import('@root/pages/suppliers/SuppliersUpdate')));
const SuppliersImportCSV = Loadable(
  lazy(() => import('@root/pages/suppliers/SuppliersImportCSV')),
);

const SuppliersCatalogList = Loadable(
  lazy(() => import('@root/pages/suppliersCatalog/SuppliersCatalogList')),
);
const SuppliersAvailabilityList = Loadable(
  lazy(() => import('@root/pages/SuppliersAvailability')),
);
/*
const SuppliersCatalogCreate = Loadable(
  lazy(() => import('@root/pages/suppliersCatalog/SuppliersCatalogCreate')),
);
const SuppliersCatalogUpdate = Loadable(
  lazy(() => import('@root/pages/suppliersCatalog/SuppliersCatalogUpdate')),
);
*/

const Profile = Loadable(lazy(() => import('@root/pages/users/Profile')));
const UsersList = Loadable(lazy(() => import('@root/pages/users/UsersList')));
const UsersNew = Loadable(lazy(() => import('@root/pages/users/UsersNew')));
const UsersUpdate = Loadable(lazy(() => import('@root/pages/users/UsersUpdate')));

// Purchase Orders Views
const PurchaseOrderCreate = Loadable(
  lazy(() => import('@root/pages/purchaseOrders/PurchaseOrderCreate')),
);
const PurchaseOrderOpen = Loadable(
  lazy(() => import('@root/pages/purchaseOrders/PurchaseOrderOpen')),
);
const PurchaseOrderSupplier = Loadable(
  lazy(() => import('@root/pages/purchaseOrders/PurchaseOrderSupplier')),
);

const MainRoutes = {
  path: '/',
  children: [
    {
      element: (
        <AuthGuard allowed_roles={ROLES.ALL_ROLES}>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ROUTES.DASHBOARD,
          element: <DashboardDefault />,
        },
        {
          path: ROUTES.ASINS,
          element: <AsinsList />,
        },
        {
          path: ROUTES.ASINS_ARCHIVED,
          element: <AsinsList />,
        },
        {
          path: ROUTES.ASIN,
          element: <AsinPreview />,
        },
      ],
    },
    {
      element: (
        <AuthGuard
          allowed_roles={[
            ROLES.ADMIN,
            ROLES.DATA_ENTRY_LV1,
            ROLES.DATA_ENTRY_LV2,
            ROLES.BUYER_LV1,
            ROLES.BUYER_LV2,
          ]}>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ROUTES.PROFILE,
          element: <Profile />,
        },
      ],
    },
    {
      element: (
        <AuthGuard
          allowed_roles={[ROLES.ADMIN, ROLES.DATA_ENTRY_LV2, ROLES.BUYER_LV1, ROLES.BUYER_LV2]}>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ROUTES.PRODUCTS,
          element: <ProductList />,
        },
        {
          path: ROUTES.PRODUCTS_NEW,
          element: <ProductsNew />,
        },
        {
          path: ROUTES.PRODUCTS_UPDATE,
          element: <ProductsUpdate />,
        },
        {
          path: ROUTES.PRODUCTS_IMPORT_FROM_CSV,
          element: <ProductsImportCSV />,
        },
      ],
    },
    {
      element: (
        <AuthGuard
          allowed_roles={[ROLES.ADMIN, ROLES.DATA_ENTRY_LV2, ROLES.BUYER_LV1, ROLES.BUYER_LV2]}>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ROUTES.PRODUCTS_CATALOG,
          element: <ProductsCatalogList />,
        },
        {
          path: ROUTES.PRODUCTS_CATALOG_NEW,
          element: <ProductsCatalogCreate />,
        },
        {
          path: ROUTES.PRODUCTS_CATALOG_UPDATE,
          element: <ProductsCatalogUpdate />,
        },
        {
          path: ROUTES.PRODUCTS_CATALOG_IMPORT_FROM_CSV,
          element: <ProductsCatalogImportCSV />,
        },
      ],
    },
    {
      element: (
        <AuthGuard
          allowed_roles={[ROLES.ADMIN, ROLES.DATA_ENTRY_LV2, ROLES.BUYER_LV1, ROLES.BUYER_LV2]}>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ROUTES.INVENTORY,
          element: <InventoryList />,
        },
        {
          path: ROUTES.INVENTORY_MOVEMENTS,
          element: <InventoryMovements />,
        },
        {
          path: ROUTES.AMAZON_INVENTORY,
          element: <AmazonList />,
        },
      ],
    },
    {
      element: (
        <AuthGuard allowed_roles={[ROLES.ADMIN]}>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ROUTES.SUPPLIERS,
          element: <SuppliersList />,
        },
        {
          path: ROUTES.SUPPLIERS_NEW,
          element: <SuppliersNew />,
        },
        {
          path: ROUTES.SUPPLIERS_UPDATE,
          element: <SuppliersUpdate />,
        },
        {
          path: ROUTES.SUPPLIERS_IMPORT_BUYLIST_CSV,
          element: <SuppliersImportCSV />,
        },
        {
          path: ROUTES.USERS,
          element: <UsersList />,
        },
        {
          path: ROUTES.USERS_NEW,
          element: <UsersNew />,
        },
        {
          path: ROUTES.USERS_UPDATE,
          element: <UsersUpdate />,
        },
      ],
    },
    {
      element: (
        <AuthGuard allowed_roles={[ROLES.ADMIN]}>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ROUTES.SUPPLIERS_CATALOG,
          element: <SuppliersCatalogList />,
        },
        {
          path: ROUTES.SUPPLIERS_AVAILABILITY,
          element: <SuppliersAvailabilityList />,
        },
        /*
        {
          path: ROUTES.SUPPLIERS_CATALOG_UPDATE,
          element: <SuppliersCatalogUpdate />,
        },
        */
      ],
    },
    {
      element: (
        <AuthGuard
          allowed_roles={[ROLES.ADMIN, ROLES.BUYER_LV1, ROLES.BUYER_LV2, ROLES.WH_STAFF]}>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ROUTES.PURCHASE_ORDERS_NEW,
          element: <PurchaseOrderCreate />,
        },
        {
          path: ROUTES.PURCHASE_ORDERS_OPEN,
          element: <PurchaseOrderOpen />,
        },
        {
          path: ROUTES.PURCHASE_ORDERS_SUPPLIER_VIEW,
          element: <PurchaseOrderSupplier />,
        },
      ],
    },
  ],
};

export default MainRoutes;
