import { useRoutes } from 'react-router-dom';

import MainRoutes from './MainRoutes';
import SettingsRoutes from './SettingsRoutes';
import LoginRoutes from './LoginRoutes';
import PublicRoutes from './PublicRoutes';

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, SettingsRoutes, LoginRoutes, PublicRoutes]);
}
