import types from '@root/store/actions';

const initialState = {
  poBySupplier: {
    onHold: [],
    added: [],
    discarded: [],
  },
  po: null,
  supplier: null,
};

const purchaseOrdersSupplierReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.PO_BY_SUPPL_LIST: {
      const { list, po, supplier } = payload;
      return {
        ...state,
        poBySupplier: {
          onHold: list.onHold,
          added: list.added,
          discarded: list.discarded,
        },
        po: po,
        supplier: supplier,
      };
    }
    case types.PO_BY_SUPPL_MOVE_TO_ADDED: {
      const { item } = payload;
      return {
        ...state,
        poBySupplier: {
          ...state.poBySupplier,
          onHold: state.poBySupplier.onHold.filter(({ id }) => id !== item.id),
          added: [state.poBySupplier.added, item],
        },
      };
    }
    default:
      return state;
  }
};

export default purchaseOrdersSupplierReducer;
