import { NavLink as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Offcanvas, ListGroup } from 'react-bootstrap';

const Drawer = (props) => {
  const { routes, show, handleClose } = props;
  const { user } = useSelector((state) => state.auth);

  return (
    <Offcanvas show={show} onHide={handleClose} style={{ width: 280 }}>
      <Offcanvas.Header closeButton>
        <h2 className="mb-0 text-primary">Aria AM</h2>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0 m-0">
        <ListGroup>
          {routes.map(({ url, title, allowedRoles = [], children }, index) => {
            const hasRequiredRole = user?.role ? allowedRoles.includes(user?.role) : false;

            if (!hasRequiredRole) {
              return null;
            }

            if (children) {
              return (
                <ListGroup.Item key={index} className="border-0 rounded-0 mb-2 px-0 py-0">
                  <span className="text-muted px-3">{title}</span>
                  {children.map((item) => {
                    const hasRequiredRole = user?.role
                      ? item.allowedRoles.includes(user?.role)
                      : false;
                    if (!hasRequiredRole) {
                      return null;
                    }
                    return (
                      <ListGroup.Item
                        action
                        key={item.id}
                        as={RouterLink}
                        to={item.url}
                        onClick={handleClose}
                        className="border-0 rounded-0 mb-1">
                        <span className="text-body">{item.title}</span>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup.Item>
              );
            }

            return (
              <ListGroup.Item
                action
                key={index}
                as={RouterLink}
                to={url}
                onClick={handleClose}
                className="border-0 rounded-0 mb-2">
                <span className="text-body">{title}</span>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Drawer;
