import fetchApi from './fetchApi';
import requests from './requests';

const tokenObtain = (body) => {
  const url = `/auth/login/`;

  return fetchApi(requests.post(url, body));
};

const logout = (body) => {
  const url = `/auth/logout/`;

  return fetchApi(requests.post(url, body));
};

const tokenRefresh = (body) => {
  const url = `/auth/refresh/`;

  return fetchApi(requests.post(url, body));
};

const passwordResetApply = (body) => {
  const url = `/password-reset/apply`;

  return fetchApi(requests.post(url, body));
};

const passwordResetRequest = (body) => {
  const url = `/password-reset/request`;

  return fetchApi(requests.post(url, body));
};

const auth = {
  tokenObtain,
  tokenRefresh,
  logout,
  passwordResetApply,
  passwordResetRequest,
};

export default auth;
