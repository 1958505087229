import requests from './requests';
import fetchApi from './fetchApi';

const getAll = () => {
  const url = '/prep-requirements/';

  return fetchApi(requests.get(url));
};

const getById = (id) => {
  const url = `/prep-requirements/${id}/`;

  return fetchApi(requests.get(url));
};

const create = (body) => {
  const url = '/prep-requirements/';

  return fetchApi(requests.post(url, body));
};

const update = (id, body) => {
  const url = `/prep-requirements/${id}/`;

  return fetchApi(requests.patch(url, body));
};

const destroy = (id) => {
  const url = `/prep-requirements/${id}/`;

  return fetchApi(requests.delete(url));
};

const prepRequirements = {
  getAll,
  getById,
  create,
  update,
  destroy,
};

export default prepRequirements;
