import { Popover } from 'react-bootstrap';

const tooltipPopover = ({ title, text, ...extraProps }) => {
  const myArray = text?.split('\n');
  return (
    <Popover id="tooltip-popover" {...extraProps}>
      <div className="mb-1">
        <span className="text-secondary h4 mb-0">{title}</span>
      </div>
      <Popover.Body className="text-body" style={{ fontSize: 15 }}>
        {myArray?.map((value, index) => (
          <p className="mb-0" key={index}>
            {value}
          </p>
        ))}
      </Popover.Body>
    </Popover>
  );
};

export default tooltipPopover;
