import types from '@root/store/actions';

const initialState = {
  supplier: null,
  catalog: [],
  catalogCount: 0,
  loading: false,
  isInitialized: false,
};

const suppliersCatalogReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SUPPLIERS_CATALOG_LIST: {
      const { supplier, catalog } = action.payload;
      return {
        ...state,
        supplier: supplier,
        catalog: catalog,
        catalogCount: catalog.length,
        isInitialized: true,
      };
    }
    case types.SUPPLIERS_CATALOG_CREATE: {
      const { data } = action.payload;
      return {
        ...state,
        catalog: [data, ...state.catalog],
        isInitialized: false,
      };
    }
    case types.SUPPLIERS_CATALOG_UPDATE: {
      const { data } = action.payload;
      return {
        ...state,
        catalog: state.catalog.map((e) => (e.id === data?.id ? data : e)),
        isInitialized: false,
      };
    }
    case types.SUPPLIERS_CATALOG_DESTROY_ITEM: {
      const { catalog_id } = action.payload;
      const deletedSet = new Set([catalog_id]);
      return {
        ...state,
        catalog: state.catalog.filter((row) => !deletedSet.has(row.id)),
      };
    }
    case types.SUPPLIERS_CATALOG_DESTROY_ALL: {
      return {
        ...state,
        catalog: [],
        catalogCount: 0,
      };
    }
    default:
      return state;
  }
};

export default suppliersCatalogReducer;
