import { ChangeEvent, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Form } from 'react-bootstrap';

import { useUI } from '@root/hooks';
import { useAppDispatch, useAppSelector } from '@root/hooks/useRedux';
import { PROFILE } from '@root/constants/navigation';
import { startLogout } from '@root/actions/authActions';
import replaceRole from '@root/utils/replaceRole';
import Avatar from '@root/assets/img/avatars/avatar-1.png';
import NavBtn from './NavBtn';
import styles from './navbar.module.scss';

const { Check } = Form;

const UserDropdown = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { ui, setUITheme } = useUI();
  const { user } = useAppSelector((state) => state.auth);

  const handleGoProfile = () => {
    navigate(PROFILE);
  };

  const handleThemeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUITheme(e.target.checked ? 'dark' : 'light');
  };

  const handleLogout = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(startLogout());
  };

  return (
    <Dropdown align="end" autoClose={true}>
      <Dropdown.Toggle className={styles.dropUser} id="dropdown-basic" variant="link">
        <div className="avatar avatar-xs" style={{ width: '2rem', height: '2rem' }}>
          <img className="avatar-img rounded-circle" src={Avatar} alt="Avatar" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ minWidth: '15rem' }}>
        <div className="my-3 px-3">
          <h6 className="fs-4 mb-0">{replaceRole(user?.role)}</h6>
          <p className="fs-4 text-secondary mb-0">{user?.email}</p>
        </div>
        <Dropdown.Divider style={{ borderStyle: 'dashed' }} />
        <NavBtn text="Profile" icon="user" onClick={handleGoProfile} />
        <div className={styles.dropItem}>
          <div className="d-flex align-items-center">
            <div className="d-inline-flex mr-2">
              <i className="fe fe-moon" />
            </div>
            <label className="flex-grow-1 mr-2 mb-0">Dark theme</label>
            <Check
              type="switch"
              id="customize-switch"
              checked={ui.theme === 'dark' ? true : false}
              onChange={handleThemeChange}
              style={{ marginRight: '-0.5rem' }}
            />
          </div>
        </div>
        <Dropdown.Divider style={{ borderStyle: 'dashed' }} />
        <NavBtn text="Logout" icon="log-out" onClick={handleLogout} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserDropdown;
