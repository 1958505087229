import types from '@root/store/actions';
import { VIRTUAL_PAGE_SIZE } from '@root/constants/lazyParams';
// import poDemo from '@root/utils/demo-data/poDemo';
// import { mutateAsinsData } from '@root/utils/data-management';

const initialState = {
  rows: [],
  // rows: mutateAsinsData(poDemo),
  totalCount: 0,
  pageSize: VIRTUAL_PAGE_SIZE,
  pageSizes: [30, 50, 100],
  currentPage: 0,
  loading: false,
  lastQuery: '',
};

const asinsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ASINS_LIST:
      return {
        ...state,
        asinsRows: action.payload,
      };
    case types.ASINS_UPDATE_LIST:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case types.ASINS_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.ASINS_FETCH_INIT:
      return {
        ...state,
        loading: true,
      };
    case types.ASINS_UPDATE_QUERY:
      return {
        ...state,
        lastQuery: action.payload,
      };
    case types.ASINS_CHANGE_PAGE_SIZE:
      return {
        ...state,
        ...action.payload,
      };
    case types.ASINS_FORCE_RELOAD:
      return {
        ...state,
        loading: false,
        lastQuery: '',
      };
    case types.ASINS_UPDATE_ROW: {
      const { id, data } = action.payload;
      const newRows = state.rows.map((row) => (row.id === id ? { ...row, ...data } : row));

      return {
        ...state,
        rows: [...newRows],
        totalCount: newRows.length,
      };
    }
    case types.ASINS_PARTIAL_UPDATE:
      let ids = action.payload.map((item) => item.id);
      let n_payload = action.payload;
      return {
        ...state,
        rows: state.rows.map((item) => {
          let payload_idex = ids.indexOf(item.id);
          if (payload_idex !== -1) {
            ids.splice(payload_idex, 1);
            let result = n_payload[payload_idex];
            n_payload.splice(payload_idex, 1);
            return result;
          } else {
            return item;
          }
        }),
      };
    case types.ASINS_DESTROY: {
      const deletedSet = new Set([action.payload]);
      return {
        ...state,
        rows: state.rows.filter((asin) => !deletedSet.has(asin.id)),
      };
    }
    case types.ASINS_DESTROY_LIST: {
      const deletedSet = new Set(action.payload);
      return {
        ...state,
        rows: state.rows.filter((asin) => !deletedSet.has(asin.id)),
      };
    }
    case types.ASINS_FILTER:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default asinsReducer;
