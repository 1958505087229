import types from '@root/store/actions';

const initialState = {
  loading: false,
  products: [],
  product: null,
  added: false,
  uploaded: false,
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.productsLoading:
      return {
        ...state,
        loading: true,
      };
    case types.productsList:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };
    case types.productsFinishLoading:
      return {
        ...state,
        loading: false,
      };
    case types.productsInitNew:
      return {
        ...state,
        added: false,
      };
    case types.productsFinishtNew:
      return {
        ...state,
        products: [action.payload, ...state.products],
        added: true,
      };
    case types.productsSetActiveProduct:
      return {
        ...state,
        product: action.payload,
      };
    case types.productsCleanActiveProduct:
      return {
        ...state,
        product: null,
      };
    case types.productsUpdateProduct:
      return {
        ...state,
        products: state.products.map((e) => (e.id === action.payload.id ? action.payload : e)),
      };
    case types.productsDelete:
      return {
        ...state,
        products: state.products.filter((product) => product.id !== action.payload),
      };

    default:
      return state;
  }
};

export default productsReducer;
