import axiosServices from '@root/utils/axios';

const setSession = (serviceToken, serviceRefresh) => {
  // console.debug('access', serviceToken);
  // console.debug('renew', serviceRefresh);
  if (serviceToken && serviceRefresh) {
    localStorage.setItem('token', serviceToken);
    localStorage.setItem('token-refresh', serviceRefresh);
    localStorage.setItem('token-init-date', new Date().getTime());
    axiosServices.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('token');
    localStorage.removeItem('token-refresh');
    localStorage.removeItem('token-init-date');
    delete axiosServices.defaults.headers.common.Authorization;
  }
};

export default setSession;
