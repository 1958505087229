import types from '@root/store/actions';

const initialState = {
  users: [],
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USERS_SET_LIST:
      return {
        ...state,
        users: action.payload,
      };
    case types.USERS_ADD:
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    case types.USERS_UPDATE:
      return {
        ...state,
        users: state.users.map((e) => (e.id === action.payload.id ? action.payload : e)),
      };
    case types.USERS_DESTROY:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload),
      };
    default:
      return state;
  }
};

export default usersReducer;
