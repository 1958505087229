import types from '@root/store/actions';

const initialState = {
  prepRequirements: [],
  countPrepReq: 0,
  loading: false,
  isInitialized: false,
};

const prepRequirementsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.PREP_REQ_CREATE: {
      const { row } = payload;
      return {
        ...state,
        prepRequirements: [...state.prepRequirements, row],
      };
    }
    case types.PREP_REQ_LIST: {
      const { data } = payload;
      return {
        ...state,
        prepRequirements: data,
        isInitialized: true,
        loading: false,
      };
    }
    case types.PREP_REQ_BY_ID:
      return {
        ...state,
      };
    case types.PREP_REQ_UPDATE: {
      const { row } = payload;
      return {
        ...state,
        prepRequirements: state.prepRequirements.map((e) =>
          e.id === row.id ? { ...e, ...row } : e,
        ),
      };
    }
    case types.PREP_REQ_PARTIAL_UPDATE: {
      const { row } = payload;
      return {
        ...state,
        prepRequirements: state.prepRequirements.map((e) =>
          e.id === row.id ? { ...e, ...row } : e,
        ),
      };
    }
    case types.PREP_REQ_DESTROY: {
      const { id } = payload;
      const deletedSet = new Set([id]);
      return {
        ...state,
        prepRequirements: state.prepRequirements.filter(({ id }) => !deletedSet.has(id)),
      };
    }
    default:
      return state;
  }
};

export default prepRequirementsReducer;
