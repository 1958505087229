import fetchApi from './fetchApi';
import requests from './requests';

const getAll = () => {
  const url = '/asins/reasons-to-archive/';

  return fetchApi(requests.get(url));
};

const getById = (id: number | string) => {
  const url = `/asins/reasons-to-archive/${id}/`;

  return fetchApi(requests.get(url));
};

const create = (body) => {
  const url = '/asins/reasons-to-archive/';

  return fetchApi(requests.post(url, body));
};

const update = (id: number | string, body) => {
  const url = `/asins/reasons-to-archive/${id}/`;

  return fetchApi(requests.patch(url, body));
};

const destroy = (id: number | string) => {
  const url = `/asins/reasons-to-archive/${id}/`;

  return fetchApi(requests.delete(url));
};

const asins = {
  getAll,
  getById,
  create,
  update,
  destroy,
};

export default asins;
