import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import type { ResponseType } from '@root/types/RequestTypes';
import { getErrorMessage, getFooterError } from '@root/utils/fetch-utils';

const MySwal = withReactContent(Swal);

const fetchApi = async (request: Promise<ResponseType>): Promise<any> => {
  return new Promise((resolve, reject) => {
    request
      .then((res: ResponseType) => {
        const data = res?.data;
        // console.debug('[Fetch Success]', res);

        if (data?.status === 'error') {
          const resData = data?.message ?? '-';
          toast.error(`Error: ${resData}`, { autoClose: 3000 });
          reject(data || 'Wrong Services');
          return;
        }
        const message = data?.message ? data.message : typeof data === 'string' && data;
        message && toast.success(message, { autoClose: 3000 });
        resolve(data);
      })
      .catch((e) => {
        // console.debug('[Fetch Error]', e);
        const status = e?.response?.status;
        const resData = e?.response?.data ? e?.response?.data : e.code + ' - ' + e.message;
        const status500 = status === 500;

        if (status === 401) {
          toast.warning(resData?.detail, { autoClose: 3000 });
          reject(resData || 'Wrong Services');
          return;
        }

        MySwal.fire({
          width: status500 ? 720 : 512,
          icon: 'error',
          title: `Error ${status}`,
          html: getErrorMessage(resData),
          footer: e?.config && getFooterError(e.config),
        });

        reject(resData || 'Wrong Services');
      });
  });
};

export default fetchApi;
