import { useState, useMemo } from 'react';

import normalize from '@root/utils/normalize';

const useSearchProducts = (products) => {
  const [query, setQuery] = useState('');
  const [filteredProducts, setFilteredProducts] = useState(products);

  useMemo(() => {
    const result = products.filter((product) => {
      return `${normalize(product?.description)}`
        .toLowerCase()
        .includes(normalize(query.toLowerCase()));
    });
    setFilteredProducts(result);
  }, [products, query]);

  return { query, setQuery, filteredProducts };
};

export default useSearchProducts;
