import fetchApi from './fetchApi';
import requests from './requests';

const getAll = () => {
  const url = '/inventory/';

  return fetchApi(requests.get(url));
};

const getById = (inventory_id) => {
  const url = `/inventory/${inventory_id}/`;

  return fetchApi(requests.get(url));
};

const create = (body) => {
  const url = '/inventory/';

  return fetchApi(requests.post(url, body));
};

const update = (inventory_id, body) => {
  const url = `/inventory/${inventory_id}/`;

  return fetchApi(requests.patch(url, body));
};

const destroy = (inventory_id) => {
  const url = `/inventory/${inventory_id}/`;

  return fetchApi(requests.delete(url));
};

const getAllAmazon = () => {
  const url = '/inventory/amazon/';

  return fetchApi(requests.get(url));
};

const inventory = {
  getAll,
  getById,
  create,
  update,
  destroy,
  getAllAmazon,
};

export default inventory;
