import fetchApi from './fetchApi';
import requests from './requests';

const getAll = () => {
  const url = '/inventory-movements/';

  return fetchApi(requests.get(url));
};

const getById = (inventory_id) => {
  const url = `/inventory-movements/${inventory_id}/`;

  return fetchApi(requests.get(url));
};

const filter = (pid) => {
  const url = `/inventory-movements/filter/${pid}/`;

  return fetchApi(requests.get(url));
};

const create = (body) => {
  const url = '/inventory-movements/';

  return fetchApi(requests.post(url, body));
};

const update = (inventory_id, body) => {
  const url = `/inventory-movements/${inventory_id}/`;

  return fetchApi(requests.patch(url, body));
};

const destroy = (inventory_id) => {
  const url = `/inventory-movements/${inventory_id}/`;

  return fetchApi(requests.delete(url));
};

const inventoryMovements = {
  getAll,
  getById,
  filter,
  create,
  update,
  destroy,
};

export default inventoryMovements;
