import { HTMLAttributes, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

// import { DASHBOARD } from '@root/constants/navigation';
import styles from './pageHeader.module.scss';

interface PageHeaderProps extends HTMLAttributes<HTMLDivElement> {
  title: any;
  preTitle?: string;
  extra?: ReactNode;
  children?: ReactNode;
  onBack?: boolean;
  noContent?: boolean;
}

const PageHeader = (props: PageHeaderProps) => {
  const { title, preTitle, extra, onBack, children, noContent, ...others } = props;
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="header" {...others}>
      <div className="header-body">
        <div className="row align-items-center">
          {onBack && (
            <div className="col-auto">
              <button
                onClick={goBack}
                className="btn btn-rounded-circle btn-white"
                style={{ paddingTop: '0.375rem', paddingBottom: '0.375rem' }}>
                <span className="fe fe-arrow-left" style={{ fontSize: 18 }}></span>
              </button>
            </div>
          )}
          <div className="col">
            {preTitle && <h6 className="header-pretitle">{preTitle}</h6>}
            <h1 className="header-title">{title}</h1>
          </div>
          {extra && (
            <div className="col-auto">
              <div className="d-flex justify-content-end align-items-center" style={{ gap: 8 }}>
                {extra}
              </div>
            </div>
          )}
        </div>
        {noContent ? (
          <>{children && children}</>
        ) : (
          <>{children && <div className={styles.content}>{children}</div>}</>
        )}
      </div>
    </div>
  );
};

export default PageHeader;
