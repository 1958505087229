import fetchApi from './fetchApi';
import requests from './requests';

const getAllByASIN = (body) => {
  const url = '/sales/amazon:filter/';

  return fetchApi(requests.post(url, body));
};

const getOrderID = (orderId) => {
  const url = `/sales/amazon:filter_id/?amazon_order_id=${orderId}`;

  return fetchApi(requests.get(url));
};

const sales = {
  getAllByASIN,
  getOrderID,
};

export default sales;
