import types from '@root/store/actions';

const initialState = {
  row: [],
  count: 0,
  loading: false,
};

const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INVENTORY_LIST:
      return {
        ...state,
        row: action.payload,
      };
    default:
      return state;
  }
};

export default inventoryReducer;
