import types from '@root/store/actions';

const initialState = {
  productsCatalog: [],
  productsCatalogCount: 0,
  loading: false,
  isInitialized: false,
};

const productsCatalogReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PRODUCTS_CATALOG_LIST: {
      const { data } = action.payload;
      return {
        ...state,
        productsCatalog: data,
        isInitialized: true,
      };
    }
    case types.PRODUCTS_CATALOG_CREATE: {
      const { data } = action.payload;
      return {
        ...state,
        productsCatalog: [data, ...state.productsCatalog],
        isInitialized: false,
      };
    }
    case types.PRODUCTS_CATALOG_UPDATE: {
      const { data } = action.payload;
      return {
        ...state,
        productsCatalog: state.productsCatalog.map((e) => (e.id === data.id ? data : e)),
        isInitialized: false,
      };
    }
    case types.PRODUCTS_CATALOG_DESTROY: {
      const { id } = action.payload;
      const deletedSet = new Set([id]);
      return {
        ...state,
        productsCatalog: state.productsCatalog.filter((row) => !deletedSet.has(row.id)),
      };
    }
    default:
      return state;
  }
};

export default productsCatalogReducer;
