import types from '@root/store/actions';

const initialState = {
  user: {},
  token: null,
  refreshToken: null,
  checking: true,
  logged: false,
  isRenewPasswordTokenValid: false,
  isInitialized: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_LOGIN: {
      const { user, token, refreshToken } = action.payload;
      return {
        ...state,
        user: user,
        token: token,
        refreshToken: refreshToken,
        checking: false,
        logged: true,
        isInitialized: true,
      };
    }
    case types.AUTH_RENEW_TOKEN:
      return {
        ...state,
        token: null,
        refreshToken: null,
        checking: false,
        logged: false,
        isInitialized: true,
      };

    case types.AUTH_LOGOUT:
      return {
        ...state,
        token: null,
        refreshToken: null,
        checking: false,
        logged: false,
        user: {},
      };

    case types.AUTH_RESET_PASSWORD:
      return {
        ...state,
        checking: false,
        logged: false,
        user: {},
      };

    case types.AUTH_VALIDATE_RESET_PASSWORD_TOKEN:
      return {
        ...state,
        isRenewPasswordTokenValid: true,
      };

    default:
      return state;
  }
};

export default authReducer;
