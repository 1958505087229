import requests from './requests';
import fetchApi from './fetchApi';

const getAll = () => {
  const url = '/inventory/catalog/';

  return fetchApi(requests.get(url));
};

const getById = (id) => {
  const url = `/inventory/catalog/${id}/`;

  return fetchApi(requests.get(url));
};

const create = (body) => {
  const url = '/inventory/catalog/';

  return fetchApi(requests.post(url, body));
};

const update = (id, body) => {
  const url = `/inventory/catalog/${id}/`;

  return fetchApi(requests.patch(url, body));
};

const destroy = (id) => {
  const url = `/inventory/catalog/${id}/`;

  return fetchApi(requests.delete(url));
};

const importFromCSV = (body) => {
  const url = `/inventory/catalog/import-from-csv/`;

  return fetchApi(requests.post(url, body));
};

const productsCatalog = {
  getAll,
  getById,
  create,
  update,
  destroy,
  importFromCSV,
};

export default productsCatalog;
