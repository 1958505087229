import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { LOGIN, UNAUTHORIZED } from '@root/constants/navigation';

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ allowed_roles, children }) => {
  const { logged, user } = useSelector((state) => state.auth);

  if (allowed_roles?.includes(user?.role) && logged) {
    return children;
  }

  return logged ? <Navigate to={UNAUTHORIZED} replace /> : <Navigate to={LOGIN} replace />;
};

export default AuthGuard;
