import uiReducer from '@root/reducers/uiReducer';
import authReducer from '@root/reducers/auth/authReducer';
import suppliersReducer from '@root/reducers/suppliersReducer';
import supplierCatalogReducer from '@root/reducers/supplierCatalogReducer';
import productsReducer from '@root/reducers/products/productsReducer';
import productsCatalogReducer from '@root/reducers/productsCatalogReducer';
import inventoryReducer from '@root/reducers/inventoryReducer';
import boxsizesReducer from '@root/reducers/boxsizes/boxsizesReducer';
import asinsReducer from '@root/reducers/asins/asinsReducer';
import prepRequirementsReducer from '@root/reducers/prepRequirements/prepRequirementsReducer';
import purchaseOrdersReducer from '@root/reducers/purchaseOrdersReducer';
import purchaseOrdersSupplierReducer from '@root/reducers/purchaseOrdersSupplierReducer';
import usersReducer from '@root/reducers/users/usersReducer';

const reducer = {
  ui: uiReducer,
  auth: authReducer,
  suppliers: suppliersReducer,
  supplierCat: supplierCatalogReducer,
  products: productsReducer,
  productsCatalog: productsCatalogReducer,
  inventory: inventoryReducer,
  boxsizes: boxsizesReducer,
  asins: asinsReducer,
  prepRequirements: prepRequirementsReducer,
  purchaseOrders: purchaseOrdersReducer,
  purchaseOrdersSupplier: purchaseOrdersSupplierReducer,
  users: usersReducer,
};

export default reducer;
