import { ImgHTMLAttributes, memo, useMemo, useState } from 'react';

import { IMAGE_FALLBACK } from '@root/constants/variables';

interface ImageWithFallbackProps extends ImgHTMLAttributes<HTMLImageElement> {
  fallback?: string;
}

const ImageWithFallback = memo((props: ImageWithFallbackProps) => {
  const { fallback = IMAGE_FALLBACK, src, ...extra } = props;
  const [imgSrc, setImgSrc] = useState(fallback);

  const onError = () => setImgSrc(fallback);

  // console.count('[.RENDER COMPONENT]');

  useMemo(() => {
    setImgSrc(src);
  }, [src]);

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img src={imgSrc ? imgSrc : fallback} onError={onError} {...extra} />;
});

export default ImageWithFallback;
