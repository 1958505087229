import * as ROLES from '@root/constants/roles';

const replaceRole = (role) => {
  let newRole = '';
  for (var i = 0; i < ROLES.ROLES_STR.length; i++) {
    if (ROLES.ROLES_STR[i].enum === role) {
      newRole = ROLES.ROLES_STR[i].value;
    }
  }
  return newRole;
};

export default replaceRole;
