import actionTypes from '@root/store/actions';

// initial temporal state
const initialState = {
  theme: 'light', // light, dark
  locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.THEME_LOCALE:
      window.localStorage.setItem(
        'THEME_LOCALE',
        action.theme ? action.theme : initialState.theme,
      );
      return {
        ...state,
        theme: action.theme ? action.theme : initialState.theme,
      };
    default:
      return state;
  }
};

export default uiReducer;
