import fetchApi from './fetchApi';
import requests from './requests';
import axios from 'axios';

function objToString(obj) {
  var str = '';
  for (var p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str += '&' + p + '=' + obj[p] + '\n';
    }
  }
  return str;
}

const getAll = () => {
  const url = `/asins/`;

  return fetchApi(requests.get(url));
};

const getAllByPagination = (page, limit, active = true) => {
  const url = `/asins/?page=${page}&limit=${limit}&active=${active}`;

  return fetchApi(requests.get(url));
};

const getAllByFilter = (page, limit, parms) => {
  const parmsStr = objToString(parms);
  const url = `/asins/filter/?page=${page}&limit=${limit}${parmsStr}`;

  return fetchApi(requests.get(url));
};

const getById = (asin_id, version) => {
  const url = `/asins/${asin_id}/?version=${version}`;

  return fetchApi(requests.get(url));
};

const getAmazonOrder = (asin) => {
  const url = `/amazon-orders/?asin=${asin}`;

  return fetchApi(requests.get(url));
};

const create = (body) => {
  const url = '/asins/';

  return fetchApi(requests.post(url, body));
};

const createFromCSV = (body) => {
  const url = '/asins/import-from-csv/';

  return fetchApi(requests.post(url, body));
};

const update = (asin_id, version, body) => {
  const url = `/asins/${asin_id}/${version ? `?version=${version}` : ''}`;

  return fetchApi(requests.patch(url, body));
};

const updateList = (params = [], body) => {
  const endpoints = params.map(
    (param) => `/asins/${param.id}/${param?.version ? `?version=${param.version}` : ''}`,
  );
  return axios.all(endpoints.map((endpoint) => fetchApi(requests.patch(endpoint, body))));
};

const updateProduct = (asin, product_id, body) => {
  const url = `/asins/${asin}/products/${product_id}`;

  return fetchApi(requests.patch(url, body));
};

const destroy = (asin_id, version) => {
  const url = `/asins/${asin_id}/?version=${version}`;

  return fetchApi(requests.delete(url));
};

const destroyList = (params = []) => {
  const endpoints = params.map(
    (param) => `/asins/${param?.asin_id}/?version=${param?.version}`,
  );
  return axios.all(endpoints.map((endpoint) => fetchApi(requests.delete(endpoint))));
};

const loadKeepaGraph = (asin_id) => {
  const url = `/asins/load-keepa-graph/${asin_id}/`;

  return fetchApi(requests.get(url));
};

const loadSalesHistory = (asin) => {
  const url = `/asins/sales-history/${asin}/`;

  return fetchApi(requests.get(url));
};

const updateAsinProfitMetrics = (asin) => {
  const url = `/asins/update_margin_profit_roi_asin/${asin}/`;

  return fetchApi(requests.get(url));
};

const asins = {
  getAll,
  getAllByPagination,
  getAllByFilter,
  getById,
  getAmazonOrder,
  create,
  createFromCSV,
  update,
  updateList,
  updateProduct,
  destroy,
  destroyList,
  loadKeepaGraph,
  loadSalesHistory,
  updateAsinProfitMetrics,
};

export default asins;
