import types from '@root/store/actions';

const initialState = {
  suppliers: [],
  suppliersCount: 0,
  loading: false,
  isInitialized: false,
};

const suppliersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SUPPLIERS_LIST: {
      const { data } = action.payload;
      return {
        ...state,
        suppliers: data,
        isInitialized: true,
        suppliersCount: data.length,
      };
    }
    case types.SUPPLIERS_CREATE: {
      const { data } = action.payload;
      return {
        ...state,
        suppliers: [data, ...state.suppliers],
        isInitialized: false,
      };
    }
    case types.SUPPLIERS_UPDATE: {
      const { data } = action.payload;
      return {
        ...state,
        suppliers: state.suppliers.map((e) => (e.id === data?.id ? data : e)),
        isInitialized: false,
      };
    }
    case types.SUPPLIERS_DESTROY: {
      const { id } = action.payload;
      const deletedSet = new Set([id]);
      return {
        ...state,
        suppliers: state.suppliers.filter((row) => !deletedSet.has(row.id)),
      };
    }
    default:
      return state;
  }
};

export default suppliersReducer;
