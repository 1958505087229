import { MouseEventHandler, ReactNode } from 'react';
import { Button } from 'react-bootstrap';

import styles from './navbar.module.scss';

interface NavbarBtnProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  text: ReactNode;
  icon?: string;
}

const NavBtn = ({ onClick, text, icon = 'settings' }: NavbarBtnProps) => (
  <Button className={styles.dropBtn} variant="white" onClick={onClick}>
    <div className="d-flex align-items-center">
      <span className="d-inline-flex mr-2">
        <i className={`fe fe-${icon}`}></i>
      </span>
      {text}
    </div>
  </Button>
);

export default NavBtn;
