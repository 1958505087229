import { HTMLAttributes, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from 'react-bootstrap';

import cls from '@root/utils/cls';
import { useResponsive } from '@root/hooks';
import styles from './smallPageHeader.module.scss';

interface SmallPageHeaderProps extends HTMLAttributes<HTMLDivElement> {
  title: any;
  preTitle?: string;
  extra?: ReactNode;
  children?: ReactNode;
  onBack?: boolean;
  noContent?: boolean;
  containerFluid?: boolean;
  rightSpace?: boolean;
  yPadding?: number;
}

const SmallPageHeader = (props: SmallPageHeaderProps) => {
  const {
    className,
    title,
    preTitle,
    extra,
    onBack,
    children,
    noContent = false,
    containerFluid = false,
    rightSpace,
    yPadding = 8,
    ...others
  } = props;
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  const goBack = () => navigate(-1);

  return (
    <div className={cls(styles.root, className)} {...others}>
      <div className={`header-body`} style={{ paddingTop: yPadding, paddingBottom: yPadding }}>
        <div
          className={cls(
            containerFluid ? 'container-fluid' : isMobile ? 'container-fluid' : 'container',
            rightSpace && styles.contentright,
          )}>
          <Stack direction="horizontal" gap={3} className="align-items-center">
            {onBack && (
              <div className="flex-shrink-1">
                <button
                  onClick={goBack}
                  className="btn btn-rounded-circle btn-white"
                  style={{ paddingTop: '0.375rem', paddingBottom: '0.375rem' }}>
                  <span className="fe fe-arrow-left" style={{ fontSize: 18 }}></span>
                </button>
              </div>
            )}
            <div>
              {preTitle && <h6 className="header-pretitle">{preTitle}</h6>}
              <h2 className="header-title">{title}</h2>
            </div>
            {extra && (
              <div className="flex-grow-1">
                <div
                  className="d-flex justify-content-end align-items-center"
                  style={{ gap: 8 }}>
                  {extra}
                </div>
              </div>
            )}
          </Stack>
          {children && <div className={!noContent ? styles.content : ''}>{children}</div>}
        </div>
      </div>
    </div>
  );
};

export default SmallPageHeader;
