import { Link as RouterLink } from 'react-router-dom';

import { DASHBOARD } from '@root/constants/navigation';
import cls from '@root/utils/cls';
import UserDropdown from './UserDropdown';
import SettingsDropdown from './SettingsDropdown';
import NavItem from './NavItem';
import styles from './navbar.module.scss';

const Navbar = (props) => {
  const { routes, handleShow } = props;

  return (
    <nav className={cls('navbar navbar-expand-lg navbar-light py-0', styles.root)}>
      <div className="container">
        <div className="d-flex align-items-center">
          <button
            className="navbar-toggler mr-3"
            type="button"
            data-toggle="collapse"
            data-target="#navbarMain"
            aria-controls="navbarMain"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleShow}>
            <i className="fe fe-menu" style={{ fontSize: 25 }}></i>
          </button>
          <div className="d-flex align-items-center">
            <RouterLink
              className="navbar-brand font-weight-bold text-primary"
              to={DASHBOARD}
              style={{ lineHeight: 1.05 }}>
              Aria AM
            </RouterLink>
            <div className="collapse navbar-collapse ml-1" id="navbarMain">
              <ul className="navbar-nav">
                {routes.map((route) => (
                  <NavItem key={route.id} {...route} />
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="navbar-user">
          <SettingsDropdown />
          <UserDropdown />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
